import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/QNEaqsgO7Hg"
    bibleGroupSrc="https://pdfhost.io/v/wylqtGyWc_Microsoft_Word_Bible_Group_Homework_425docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Joy-filled Prayer - The Pursuit of Happiness" />
  </Layout>
)

export default SermonPost
